import React from "react";
import { Grid } from "@material-ui/core";

const Chapter8 = () => {
  return (
    <>
      <h2>What can I do?</h2>
      <p>
        While some total dissolved solids get into our waterways via natural
        erosion processes, we can all help to minimize our impact. Products we
        use at our homes, businesses and schools can be washed off yards,
        driveways, streets, and parking lots into storm sewers and directly into
        rivers and creeks. When spreading salt for de-icing in the winter, do
        not over-apply and use only what you need. Also, do not apply
        fertilizers to lawns immediately before storms as this can lead to
        increased runoff. Enforcement of properly maintaining construction sites
        to prevent soil and sediment erosion is an important method of keeping
        TDS out of surface waters.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="South Platte River"
              className="img img-responsive"
              src="/static/img/storylines/splatte_web-980x560.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              The South Platte River can have high levels of TDS and
              conductivity.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter8;
