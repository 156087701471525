import React from "react";

const Chapter5 = () => {
  return (
    <>
      <h2>How does pH, temperature and dissolved oxygen get into the river?</h2>
      <p>
        pH, temperature, and dissolved oxygen are measures of the water quality
        and are not pollutants themselves.
      </p>
    </>
  );
};

export default Chapter5;
