import React from "react";
import { Grid } from "@material-ui/core";

const Chapter3 = () => {
  return (
    <>
      <h2>How are TSS and turbidity measured?</h2>
      <p>
        Total suspended solids (TSS) are measured are measured through
        laboratory analysis. The water sample is filtered through a pre-weighed
        filter. The residue retained on the filter is dried in an oven at 103 to
        105° C. The increase in weight of the filter represents the total
        suspended solids.
      </p>
      <p>
        Turbidity is a surrogate for total suspended solids because it can be
        quickly and inexpensively measured in the field using handheld meters or
        probes that are secured in place and collect data continuously.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="TSS Measuring"
              className="img img-responsive"
              src="/static/img/storylines/wss-property-turbidity-example-numbers.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              Turbidity is measured in Nephelometric Turbidity Units (NTU).
              These bottles show various turbidity levels.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter3;
