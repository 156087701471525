import React from "react";

const Chapter4 = () => {
  return (
    <>
      <h2>How do CECs get into the South Platte River?</h2>
      <p>
        Discharge from wastewater treatment facilities flowing into surface
        water or groundwater is a significant source of CECs. Personal care
        products and pharmaceuticals are excreted, washed off, or disposed of
        via the sanitary sewer system, and then processed by a wastewater
        treatment facility or septic system. These treatment systems are not
        specifically designed to remove CECs, and there are no required
        discharge permits or water quality standards. Many CECs lack water
        quality standards because information and research on environmental
        occurrence, impacts, and toxicology is incomplete or still ongoing. Most
        modern wastewater treatments facilities remove or degrade the majority
        of CECs that enter them, even though they are not designed to do so.
        However, a small fraction of most CECs and the majority of a few CECs
        are not removed by wastewater treatments. These CECs leave the treatment
        plant along with the processed wastewater and enter the surface water or
        groundwater system.
      </p>
      <p>
        In addition to point sources like industrial or wastewater discharges,
        non-point sources can also contribute CECs to surface or groundwater.
        Non-point sources include stormwater runoff, or irrigation water that is
        applied to croplands, urban and residential lands, or parks. This water
        can transport a portion of the pesticides or herbicides that were
        applied to that land into storm sewers (in urban and suburban areas) and
        then to rivers. Other CECs such as combustion by-products from driving
        cars are also washed off urban lands and end up in local streams.
      </p>
      <p>
        It is of interest to note that the Metro Water Recovery 2014 Robert W.
        Hite treatment plant upgrade to improve the removal of nutrients
        apparently has had secondary benefits of reducing concentrations of some
        CECs. In the graphics below, the concentrations for the CECs Metformin
        (a very common anti-diabetic drug and an endocrine disruptor),
        Gabapentin (an epileptic treatment and neuropathic pain reliever), and
        Oxycodone (a narcotic pain reliever) are shown for the sampling site
        (SP-9 or SPCC) downstream of the MWRD’s Hite facility. Note that the
        2015 concentrations (shown in red) are mostly below the same seasonal
        results for previous years.
      </p>
    </>
  );
};

export default Chapter4;
