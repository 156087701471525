import React from "react";
import { Grid } from "@material-ui/core";

const Chapter3 = () => {
  return (
    <>
      <h2>How are TDS and conductivity measured?</h2>
      <p>
        Total dissolved solids are measured through laboratory analysis. The
        water sample is filtered, which captures only the dissolved solids, and
        then the filtrate (the water that passes through the filter) is
        evaporated in a pre-weighed dish and dried in an oven at 180° C. The
        increase in weight of the dish represents the concentration of total
        dissolved solids.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Laboratory Analysis Graphic"
              className="img img-responsive"
              src="/static/img/storylines/4d6bf7fe-303d-4951-9be4-8e6fbabcee2b_TSS_TDS.4c3a7182.1200x771.avif"
              style={{ width: "100%" }}
            />
            <figcaption>
              TSS and TDS laboratory analysis graphic; TSS is represented by the
              suspended particles that get caught by the filter and TDS is
              represented in the filtered water.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
      <p>
        Conductivity is a surrogate for total dissolved solids because it can be
        quickly and inexpensively measured in the field using handheld meters or
        probes that are secured in place and collect data continuously.
      </p>
    </>
  );
};

export default Chapter3;
