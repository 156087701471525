import React from "react";

const Chapter2 = () => {
  return (
    <>
      <h2>What is TDS and conductivity?</h2>
      <p>
        The measure of total dissolved solids in water is the sum of all the
        substances, organic and inorganic, dissolved in water. Calcium,
        magnesium, sodium, potassium, bicarbonate, sulfate, chloride, nitrate,
        and silica typically make up most of the dissolved solids in water.
        Combinations of these ions—sodium and chloride, for example—form salts,
        and salinity is another term commonly used to describe the dissolved
        solids content of water.
      </p>
      <p>
        Specific conductance, or conductivity, measures the ability of water to
        transmit an electrical current and can be used as a surrogate
        measurement for total dissolved solids. The ability to conduct an
        electrical current increases with the amount of dissolved ions in the
        water.
      </p>
    </>
  );
};

export default Chapter2;
