import React from "react";
import {
  Table as StyledTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import styled from "styled-components/macro";

const Table = styled(StyledTable)`
  min-width: 650px;
  border: 1px solid ${(props) => props.theme.palette.divider};
`;

const CenteredTableCell = styled(TableCell)`
  padding: 8px !important;
  text-align: center !important;
  border-right: 1px solid ${(props) => props.theme.palette.divider} !important;
  border-left: 1px solid ${(props) => props.theme.palette.divider} !important;
`;

const StyledTableCell = styled(CenteredTableCell)`
  border-bottom: 0 !important;
`;

const Chapter6 = () => {
  const rows = [
    {
      temperatureTier: "Warm Stream Tier 1",
      tierCode: "WS-I",
      species: "Common Shiner, Johnny Darter, Orangethroat Darter, Stonecat",
      applicableMonths: "March – Nov.",
      weeklyAvgTemp: 24.2,
      dailyMaxTemp: 29.0,
    },
    {
      temperatureTier: "",
      tierCode: "",
      species: "",
      applicableMonths: "Dec. – Feb.",
      weeklyAvgTemp: 12.1,
      dailyMaxTemp: 24.6,
    },
  ];
  return (
    <>
      <h2>Regulation & Standards</h2>
      <p>
        Colorado Regulation 31, the Basic Standards and Methodologies for
        Surface Water, sets pH, temperature, and dissolved oxygen standards
        based on the uses of the waterbody for the entire state. The standards
        vary to generally protect recreational activities such as swimming, cold
        or warm water aquatic life, agricultural water supply, and domestic
        water supply. The South Platte River has its own regulation for surface
        water, Colorado Regulation 38. Regulation 38 specifies pH, temperature,
        and dissolved oxygen for specific river segments and lakes within the
        basin. The regulation sets chronic DO > 5 mg/L and acute pH between 6.5
        and 9.
      </p>
      <p>
        Here are the aquatic life table value standards for temperature for the
        South Platte River:
      </p>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontWeight: 900 }}>
                Temperature Tier
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 900 }}>
                Tier Code
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 900 }}>
                Species Expected to be Present
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 900 }}>
                Applicable Months
              </StyledTableCell>
              <CenteredTableCell style={{ fontWeight: 900 }} colSpan={2}>
                Temperature Standard (°C)
              </CenteredTableCell>
            </TableRow>
            <TableRow>
              <CenteredTableCell></CenteredTableCell>
              <CenteredTableCell></CenteredTableCell>
              <CenteredTableCell></CenteredTableCell>
              <CenteredTableCell></CenteredTableCell>
              <CenteredTableCell style={{ fontWeight: 900 }}>
                Weekly Average Temperature
              </CenteredTableCell>
              <CenteredTableCell style={{ fontWeight: 900 }}>
                Daily Maximum Temperature
              </CenteredTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell>{row.temperatureTier}</StyledTableCell>
                <StyledTableCell>{row.tierCode}</StyledTableCell>
                <StyledTableCell>{row.species}</StyledTableCell>
                <CenteredTableCell>{row.applicableMonths}</CenteredTableCell>
                <CenteredTableCell>{row.weeklyAvgTemp}</CenteredTableCell>
                <CenteredTableCell>{row.dailyMaxTemp}</CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        There are no EPA drinking water primary standards for pH, temperature,
        or dissolved oxygen. However, the EPA does have secondary water
        standards for pH. The EPA recommends that drinking water pH falls
        between 6.5-8.5. Low pH water can have a bitter metallic taste and cause
        corrosion. High pH water can feel slippery, taste like soda, and can
        cause deposits.
      </p>
    </>
  );
};

export default Chapter6;
