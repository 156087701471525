import React from "react";
import { Grid } from "@material-ui/core";

const Chapter7 = () => {
  return (
    <>
      <h2>What are the seasonal cycles of pH and dissolved oxygen?</h2>
      <p>
        Water temperature is most commonly related to the air temperature but
        can also be impacted by discharges to the stream. Water temperature
        affects dissolved oxygen concentrations and pH in a river or water body.
        Cold water can hold more dissolved oxygen than warm water. In winter and
        early spring, when the water temperature is low, the dissolved oxygen
        concentration is high. In summer and fall, when the water temperature is
        high, the dissolved oxygen concentration is often lower. pH also changes
        with temperature, but it doesn’t mean the water is more acidic or basic.
        pH is calculated by the number of hydrogen ions in the sample. Pure
        water only has a pH of 7.0 at 25 degrees Celsius.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Temperature and DO"
              className="img img-responsive"
              src="/static/img/storylines/temp-vs-DO.JPG"
              style={{ width: "100%" }}
            />
            <figcaption>
              Temperature and DO values from the USGS gage on the South Platte
              River above Strontia Springs Reservoir, Colorado. DO is affected
              by temperature fluctuations.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Temperature and DO"
              className="img img-responsive"
              src="/static/img/storylines/temperature-vs-ph.JPG"
              style={{ width: "100%" }}
            />
            <figcaption>
              Temperature and pH values from the USGS gage on the South Platte
              River above Strontia Springs Reservoir, Colorado. pH is affected
              by temperature fluctuations.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter7;
