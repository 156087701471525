import React from "react";

const Chapter9 = () => {
  return (
    <>
      <h2>What can I do?</h2>
      <p>
        Use native vegetation or grass to cover and stabilize exposed soil on
        lawns to prevent sediment wash off. Reduce turf areas by planting native
        species to reduce and filter pollutant-laden runoff and prevent the
        spread of invasive, non-native plant species into the storm sewer
        system.
      </p>
    </>
  );
};

export default Chapter9;
