import React from "react";

const Chapter4 = () => {
  return (
    <>
      <h2>Why should I care?</h2>
      <p>
        pH, temperature, and dissolved oxygen are immediate and inexpensive
        measures of a water body’s health. They can indicate a problem
        immediately or indicate the severity of a known problem.
      </p>
      <p>
        The pH of water determines the solubility (amount that can be dissolved
        in the water) and biological availability (amount that can be utilized
        by aquatic life) of chemical constituents such as nutrients (phosphorus,
        nitrogen, and carbon) and heavy metals (lead, copper, cadmium, etc.).
        For example, in addition to affecting how much and what form of
        phosphorus is most abundant in the water, pH also determines whether
        aquatic life can use it. In the case of heavy metals, the degree to
        which they are soluble determines their toxicity. Metals tend to be more
        toxic at lower pH because they are more soluble.”
      </p>
      <p>
        Temperature governs the kinds of organisms that can live in rivers and
        lakes. Temperature range exceedances can stress fish and other aquatic
        species. Temperature is also important because of its influence on water
        chemistry. The rate of chemical reactions generally increases at higher
        temperature.
      </p>{" "}
      <p>
        Although water molecules contain an oxygen atom, this oxygen is not what
        is needed by aquatic organisms living in natural waters. A small amount
        of oxygen, up to about ten molecules of oxygen per million of water, is
        actually dissolved in water. This dissolved oxygen is breathed by fish
        and zooplankton and is needed by them to survive.
      </p>
    </>
  );
};

export default Chapter4;
