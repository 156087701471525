import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemText,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

//388px
const DataSources = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/report-organizations"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-organizations`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Organization Name",
      field: "organization_name",
    },
    {
      title: "Organization ID",
      field: "organization_id",
    },
    {
      title: "External Data Link",
      field: "loc_url",
      render: (rowData) => {
        return rowData.agency_contact_url ? (
          <a target="_blank" href={rowData.agency_contact_url} rel="noreferrer">
            More Info
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Full Agency Name",
      field: "org_long_name",
    },
  ];

  return (
    <>
      <Helmet title="Data Sources" />
      <Typography variant="h3" gutterBottom display="inline">
        Data Sources
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Data Sources</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper mb={4}>
        <Alert severity="info">
          <AlertTitle>
            <strong>
              This database is populated from the following public data
              repositories and the Organizations below are listed as the
              original data provider in these public sources.
            </strong>
          </AlertTitle>

          <div>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      href="http://www.coloradowaterdata.org/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Colorado Data Sharing Network
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      href="https://www.waterqualitydata.us/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      National Water Quality Data Portal
                    </Link>
                  }
                />
              </ListItem>
            </List>
          </div>
          <AlertTitle>
            *** Please note that the data in this system are NOT quality checked
            or modified from these public sources. They are also filtered to
            include only parameters and monitoring locations related to the
            educational goals of this tool. To access complete data sets from
            CDSN or the National Portal, please visit those sites at the links
            above and use their download tools. ***
          </AlertTitle>
        </Alert>
      </Paper>

      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Data Sources"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default DataSources;
