import React from "react";

const Chapter5 = () => {
  return (
    <>
      <h2>
        How do total suspended solids (TSS) get into the South Platte River?{" "}
      </h2>
      <p>
        Solids primarily enter waterways through stormwater runoff and erosion.
        Soil, clay, organic matter, chemicals, and other solids can be washed
        off the land we use for houses, yards, streets, and parking lots, into
        storm sewers and streams after rain or snow melt. Once in the river,
        some of these materials dissolve, some are deposited in stream beds and
        others remain suspended. Total suspended solids can also enter a
        waterway from point sources such as wastewater treatment facilities or
        mines.
      </p>
    </>
  );
};

export default Chapter5;
