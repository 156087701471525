import React from "react";

import WaterQualityPage from "../WaterQualityPage";
import Chapter1 from "./Chapter1";
import Chapter2 from "./Chapter2";
import Chapter3 from "./Chapter3";
import Chapter4 from "./Chapter4";
import Chapter5 from "./Chapter5";
import Chapter6 from "./Chapter6";
import Chapter7 from "./Chapter7";
import Chapter8 from "./Chapter8";

function TotalDissolvedSolids() {
  const links = [
    {
      chapter: "Chapter 1",
      links: [],
    },
    {
      chapter: "Chapter 2",
      links: [
        {
          title: "Source - USGS, Chloride, Salinity, and Dissolved Solids",
          url: "https://www.usgs.gov/mission-areas/water-resources/science/chloride-salinity-and-dissolved-solids?qt-science_center_objects=0#qt-science_center_objects",
        },
      ],
    },
    {
      chapter: "Chapter 3",
      links: [
        {
          title:
            "Source - DataStream, Total Suspended Solids and Total Dissolved Solids",
          url: "https://datastream.org/en/guide/total-suspended-solids-and-total-dissolved-solids#:~:text=These%20particles%2C%20or%20'solids',total%20dissolved%20solids%20(TDS)",
        },
      ],
    },
    {
      chapter: "Chapter 4",
      links: [
        {
          title: "Source - USGS, Chloride, Salinity, and Dissolved Solids",
          url: "https://www.usgs.gov/mission-areas/water-resources/science/chloride-salinity-and-dissolved-solids?qt-science_center_objects=0%23qt-science_center_objects",
        },
      ],
    },
    {
      chapter: "Chapter 5",
      links: [
        {
          title: "Source - USGS, Chloride, Salinity, and Dissolved Solids",
          url: "https://www.usgs.gov/mission-areas/water-resources/science/chloride-salinity-and-dissolved-solids?qt-science_center_objects=0#qt-science_center_objects",
        },
        {
          title: "Source - Columbia News, Road Salt Harms Environment",
          url: "https://news.climate.columbia.edu/2018/12/11/road-salt-harms-environment/",
        },
      ],
    },
    {
      chapter: "Chapter 6",
      links: [],
    },
    {
      chapter: "Chapter 7",
      links: [
        {
          title: "Source - USGS, South Platte River at Englewood, CO",
          url: "https://waterdata.usgs.gov/monitoring-location/06711565/#parameterCode=00060&startDT=2022-04-01&endDT=2022-06-01",
        },
        {
          title: "Source - Weather.gov, South Platte River Flooding",
          url: "https://www.weather.gov/lbf/southplatte_platte_flooding_2013",
        },
      ],
    },
    {
      chapter: "Chapter 8",
      links: [
        {
          title:
            "Source - Water Education Colorado, Is the South Platte River Too Salty?",
          url: "https://www.watereducationcolorado.org/fresh-water-news/is-the-south-platte-river-too-salty-new-study-to-examine-toxicity/",
        },
      ],
    },
  ];

  const title = "Total Dissolved Solids and Conductivity";

  const description =
    "Learn not only what Total Dissolved Solids are, but additionally what can be done to reduce its pollutant load in the South Platte River and its tributaries.";

  const chapters = [
    {
      number: "1",
      description: "Intro",
      body: <Chapter1 />,
    },
    {
      number: "2",
      description: "What is TDS and conductivity?",
      body: <Chapter2 />,
    },
    {
      number: "3",
      description: "How are TDS and conductivity measured?",
      body: <Chapter3 />,
    },
    {
      number: "4",
      description: "Why should I care?",
      body: <Chapter4 />,
    },
    {
      number: "5",
      description: "How do total dissolved solids (TDS) get into the river?",
      body: <Chapter5 />,
    },
    {
      number: "6",
      description: "Regulation & Standards",
      body: <Chapter6 />,
    },
    {
      number: "7",
      description: "When are TDS concentrations and conductivity the highest?",
      body: <Chapter7 />,
    },
    {
      number: "8",
      description: "What can I do?",
      body: <Chapter8 />,
    },
  ];

  return (
    <WaterQualityPage
      links={links}
      title={title}
      description={description}
      chapters={chapters}
    />
  );
}

export default TotalDissolvedSolids;
