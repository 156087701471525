import React from "react";
import { Grid } from "@material-ui/core";

const Chapter3 = () => {
  return (
    <>
      <h2>How are they measured?</h2>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Multi-parameter Monitor"
              className="img img-responsive"
              src="/static/img/storylines/wss-qw-water-quality-multi-meter.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              Example of a multi-parameter monitor used to record in-situ
              water-quality measurements.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
      <p>
        pH, temperature, and dissolved oxygen are typically measured in the
        field using handheld meters or sondes that are secured in place and
        collect data continuously. The meters and sondes that analyze the water
        must be calibrated for the parameters being measured according to
        manufacturer recommendations prior to use to ensure the quality of the
        data collected.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Scientist measuring WQ"
              className="img img-responsive"
              src="/static/img/storylines/wss-property-dissolved-oxygen-measuring_0.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              USGS scientist measuring various water-quality conditions in Holes
              Creek at Huffman Park in Kettering, Ohio.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter3;
