import React from "react";

const Chapter1 = () => {
  return (
    <>
      <h2>Total Suspended Solids & Turbidity Storyline</h2>
      <p>
        Don’t know much about total suspended solids (TSS) and turbidity? The
        storyline is the perfect place to start. Learn not only what TSS and
        turbidity are, but additionally what can be done to reduce its pollutant
        load in the South Platte River and its tributaries.
      </p>
    </>
  );
};

export default Chapter1;
