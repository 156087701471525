import React from "react";
import { Grid } from "@material-ui/core";

const Chapter4 = () => {
  return (
    <>
      <h2>Why should I care?</h2>
      <p>
        The U.S. Environmental Protection Agency identifies sediment as the
        second most widespread cause of impairment of the nation’s rivers and
        streams. High TSS and turbidity levels are often accompanied by high
        concentrations of other contaminants like E. coli, Giardia, or increased
        metals loads, which are harmful to human health. Elevated TSS levels
        also decrease sunlight penetration in waterways, which can impair growth
        of plants, fish, and other aquatic organisms.
      </p>

      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Sediment Influx Tributary"
              className="img img-responsive"
              src="/static/img/storylines/wss-sw-sediment-influx-tributary_0.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              Sediment-laden water from a tributary entering the clearer
              Chattahoochee River near Atlanta, Georgia.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter4;
