import React from "react";

import WaterQualityPage from "../WaterQualityPage";
import Chapter1 from "./Chapter1";
import Chapter2 from "./Chapter2";
import Chapter3 from "./Chapter3";
import Chapter4 from "./Chapter4";
import Chapter5 from "./Chapter5";
import Chapter6 from "./Chapter6";
import Chapter7 from "./Chapter7";
import Chapter8 from "./Chapter8";
import Chapter9 from "./Chapter9";

function TotalSuspendedSolids() {
  const links = [
    {
      chapter: "Chapter 1",
      links: [],
    },
    {
      chapter: "Chapter 2",
      links: [
        {
          title: "Source - USGS, Turbidity and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/turbidity-and-water",
        },
      ],
    },
    {
      chapter: "Chapter 3",
      links: [
        {
          title: "Source - USGS, Turbidity and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/turbidity-and-water",
        },
      ],
    },
    {
      chapter: "Chapter 4",
      links: [
        {
          title: "Source - USGS, Sediment and Suspended Sediment",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/sediment-and-suspended-sediment",
        },
      ],
    },
    {
      chapter: "Chapter 5",
      links: [],
    },
    {
      chapter: "Chapter 6",
      links: [],
    },
    {
      chapter: "Chapter 7",
      links: [],
    },
    {
      chapter: "Chapter 8",
      links: [],
    },
    {
      chapter: "Chapter 9",
      links: [],
    },
  ];

  const title = "Total Suspended Solids and Turbidity";

  const description =
    "Learn not only what Total Suspended Solids and Turbidity is, but additionally what can be done to reduce its pollutant load in the South Platte River and its tributaries.";

  const chapters = [
    {
      number: "1",
      description: "Intro",
      body: <Chapter1 />,
    },
    {
      number: "2",
      description: "What are TSS and turbidity?",
      body: <Chapter2 />,
    },
    {
      number: "3",
      description: "How are TSS and turbidity measured?",
      body: <Chapter3 />,
    },
    {
      number: "4",
      description: "Why should I care?",
      body: <Chapter4 />,
    },
    {
      number: "5",
      description:
        "How do total suspended solids (TSS) get into the South Platte River?",
      body: <Chapter5 />,
    },
    {
      number: "6",
      description: "Regulation & Standards",
      body: <Chapter6 />,
    },
    {
      number: "7",
      description: "When are TSS concentrations and turbidity the highest?",
      body: <Chapter7 />,
    },
    {
      number: "8",
      description: "Impacts of storm runoff",
      body: <Chapter8 />,
    },
    {
      number: "9",
      description: "What can I do?",
      body: <Chapter9 />,
    },
  ];

  return (
    <WaterQualityPage
      links={links}
      title={title}
      description={description}
      chapters={chapters}
    />
  );
}

export default TotalSuspendedSolids;
