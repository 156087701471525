export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-105.25976, 39.53183];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 8.2,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "spwqat-locations-circle";
export const WELLS_LABELS_LAYER_ID = "spwqat-locations-symbol";
export const INIT_FILTER_VALUES = {
  basin: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "basin",
    options: [],
    type: "multi-select",
    value: [],
  },
  organizations: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "organizations",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  graphMode: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "ndx",
    type: "graphMode",
    value: [""],
  },

  moreThanA: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "more_than_a",
    type: "boolean",
    value: false,
  },
  moreThanB: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "more_than_b",
    type: "boolean",
    value: false,
  },
  moreThanC: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "more_than_c",
    type: "boolean",
    value: false,
  },
  dataRecentA: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "data_recent_a",
    type: "boolean",
    value: false,
  },
  dataRecentB: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "data_recent_b",
    type: "boolean",
    value: false,
  },
};

const layerId = "spwqat-locations-circle";
export const INIT_STYLE_VALUES = () => {
  return {
    basin: {
      id: "basin",
      layerId,
      layerFieldName: "basin",
      name: "Basin",
      options: [],
      type: "multi-select",
      value: [],
      paint: {
        "circle-color": [
          "match",
          ["get", "basin"],
          ["Clear Creek"],
          "#1F77B4",
          ["Middle South Platte-Cherry Creek"],
          "#AEC7E8",
          ["South Platte Headwaters"],
          "#FF7F0E",
          ["Upper South Platte"],
          "#FFBB78",
          "#000",
        ],
      },
    },
    organizations: {
      id: "organizations",
      layerId,
      layerFieldName: "organizations",
      name: "Organizations",
      options: [],
      type: "multi-select-array",
      value: [],
      paint: {
        "circle-color": [
          "match",
          ["get", "organization"],
          "No Values Available",
          "#fff",
          "#fff",
        ],
      },
    },
    default: {
      id: "default",
      layerId,
      layerFieldName: "",
      name: "Default",
      paint: {
        "circle-color": "#1e8dd2",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    },
  };
};
