import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  isWidthUp,
  TextField,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import Chips from "../../../components/Chips";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useApp } from "../../../AppProvider";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import QueryAndDownloadMap from "../../../components/map/QueryAndDownloadMap";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  // height: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
  // color: ${(props) => props.theme.palette.common.white};
`;

const MapContainer = styled.div`
  height: 1070px;
  width: 100%;
`;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

const QueryAndDownload = ({ width }) => {
  const { doToast, currentUser } = useApp();

  const initFilterValues = {
    parameterGroups: [],
    parameters: [],
    basins: [],
    waterbodies: [],
    counties: [],
    orgniazations: [],
    locations: [],
    startDate: null,
    endDate: null,
    uuid: null,
    uuidOrDefault: null,
    recordCount: "",
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const { data: userSelections } = useQuery(
    [`user-filters/${currentUser}`, currentUser],
    async () => {
      if (currentUser?.sub) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/user-filters/${currentUser?.sub}`
          );
          return data ?? {};
        } catch (err) {
          console.error(err);
        }
      }
      return {};
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [parameterGroups] = useFetchData(
    "list-parameter-groups-for-download",
    [],
    false
  );
  const [basins] = useFetchData("list-basins-for-download", [], false);
  const [waterbodies] = useFetchData(
    "list-waterbodies-for-download",
    [],
    false
  );
  const [counties] = useFetchData("list-counties-for-download", [], false);
  const [organizations] = useFetchData(
    "list-organizations-for-download",
    [],
    false
  );

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (
      parameterGroups.length &&
      basins.length &&
      waterbodies.length &&
      counties.length &&
      organizations.length
    ) {
      onFilterSelectAll(
        "parameterGroups",
        parameterGroups,
        "parameter_group_name"
      );
      onFilterSelectAll("basins", basins, "basin");
      onFilterSelectAll("waterbodies", waterbodies, "waterbody");
      onFilterSelectAll("counties", counties, "county");
      onFilterSelectAll("organizations", organizations, "organization");
      setHasFiltersLoaded(true);
    }
  }, [parameterGroups, basins, waterbodies, waterbodies, organizations]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    [
      "list-parameters-for-download-filters",
      filterValues.parameterGroups,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-parameters-for-download-filters`,
            {
              parameterGroups: filterValues.parameterGroups,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const cleanFilter = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.indexOf(x[filterField]) !== -1) {
        newParams.push(x[filterField]);
      }
    });
    return newParams;
  };

  useEffect(() => {
    if (parameters?.length && !hasParametersLoaded && userSelections) {
      setHasParametersLoaded(true);

      if (
        Object.entries(userSelections).length &&
        userSelections.user_uuid !== "public"
      ) {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            parameters: userSelections.parameters,
            startDate: convertUTCDateToLocalDate(
              new Date(userSelections.start_date)
            ),
            endDate: convertUTCDateToLocalDate(
              new Date(userSelections.end_date)
            ),
          };
        });
      } else {
        onFilterSelectAll("parameters", parameters, "parameter_ndx");
      }
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: cleanFilter(
            filterValues.parameters,
            parameters,
            "parameter_ndx"
          ),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  const [hasLocationsLoaded, setHasLocationsLoaded] = useState(false);
  const { data: locations } = useQuery(
    [
      "list-locations-for-download-filters",
      filterValues.basins,
      filterValues.waterbodies,
      filterValues.counties,
      filterValues.organizations,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-locations-for-download-filters`,
            {
              basins: filterValues.basins,
              waterbodies: filterValues.waterbodies,
              counties: filterValues.counties,
              organizations: filterValues.organizations,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (locations?.length && !hasLocationsLoaded && userSelections) {
      setHasLocationsLoaded(true);

      if (Object.entries(userSelections).length) {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            locations: locations.filter((location) =>
              userSelections.locations.includes(location.location_ndx)
            ),
          };
        });
      } else {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            locations: locations,
          };
        });
      }
    }
  }, [parameters]); //eslint-disable-line

  const cleanFilterLocations = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.find((item) => item[filterField] === x[filterField])) {
        newParams.push(x);
      }
    });
    return newParams;
  };

  useEffect(() => {
    if (hasLocationsLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: cleanFilterLocations(
            filterValues.locations,
            locations,
            "location_ndx"
          ),
        };
      });
    }
  }, [locations]); //eslint-disable-line

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  const getFilterNameByIndex = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterIndex] === name);
    return filter[filterField];
  };

  const handleFilterValues = (name, value) => {
    if (["parameters"].includes(name)) {
      const savedEvent = Object.assign({}, value);
      setFilterValues((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(
          +savedEvent.currentTarget.dataset.value
        );
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(+savedEvent.currentTarget.dataset.value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else if (
      [
        "parameterGroups",
        "basins",
        "waterbodies",
        "counties",
        "organizations",
      ].includes(name)
    ) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      //dates
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const { data: recordCount } = useQuery(
    ["report-user-filters-recordcounts", currentUser],
    async () => {
      if (currentUser?.sub) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/report-user-filters-recordcounts`,
            {
              uuid: currentUser?.sub,
            }
          );
          return data[0]?.sel_recordcount ?? 0;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (recordCount) {
      handleFilterValues("recordCount", recordCount);
    }
  }, [recordCount]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmitFilters = () => {
    setIsSubmitLoading(true);
    return (async () => {
      try {
        const user = currentUser?.sub || "public";
        await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/user-filters`, {
          user_uuid: user,
          parameters: filterValues.parameters,
          locations: filterValues.locations.map(
            (location) => location.location_ndx
          ),
          start_date: filterValues.startDate,
          end_date: filterValues.endDate,
        });

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/report-user-filters-recordcounts`,
            {
              uuid: user,
            }
          );
          handleFilterValues("recordCount", data[0]?.sel_recordcount ?? 0);
        } catch (err) {
          console.error(err);
        }

        doToast("success", "New filters were applied to the database");
        setIsSubmitLoading(false);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsSubmitLoading(false);
      }
    })();
  };

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = () => {
    setIsExportLoading(true);
    async function send() {
      try {
        let { data: timeseriesData } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/report-user-selected-data`,
          {
            uuid: currentUser?.sub ?? "public",
          }
        );

        const timeseriesDataCsvString = [
          [
            `"Results for constituents: ${filterValues.parameters
              .map((parameter) =>
                getFilterNameByIndex(
                  parameter,
                  parameters,
                  "param_abbrev",
                  "parameter_ndx"
                )
              )
              .join(", ")}"`,
          ],
          [
            `"Results for locations: ${filterValues.locations
              .map((location) => location.location_id)
              .join(", ")}"`,
          ],
          [`"Time Series Data"`],
          [
            "Location ID",
            "Location Name",
            "Constituent",
            "Constituent Group",
            "Activity Date",
            "Data Value",
            "Units",
            "Non-detect?",
            "Basin",
            "Waterbody",
            "County",
            "Organization",
            "HUC12",
            "Benchmark 0",
            "Benchmark 1",
            "Benchmark 2",
            "Benchmark 3",
            "Benchmark 4",
          ],
          ...timeseriesData.map((item) => [
            `"${item.location_id ?? ""}"`,
            `"${item.location_name ?? ""}"`,
            `"${item.parameter ?? ""}"`,
            `"${item.parameter_group_name ?? ""}"`,
            `"${item.collect_date ?? ""}"`,
            `"${item.result ?? ""}"`,
            `"${item.units ?? ""}"`,
            `"${item.nondetect ?? ""}"`,
            `"${item.basin ?? ""}"`,
            `"${item.waterbody ?? ""}"`,
            `"${item.county ?? ""}"`,
            `"${item.organization ?? ""}"`,
            `"${item.huc12 ?? ""}"`,
            `"${item.benchmark0 ?? ""}"`,
            `"${item.benchmark1 ?? ""}"`,
            `"${item.benchmark2 ?? ""}"`,
            `"${item.benchmark3 ?? ""}"`,
            `"${item.benchmark4 ?? ""}"`,
          ]),
        ]
          .map((e) => e.join(","))
          .join("\n");

        const a = document.createElement("a");
        a.href =
          "data:attachment/csv," + encodeURIComponent(timeseriesDataCsvString);
        a.target = "_blank";
        a.download = `Time Series Data.csv`;
        document.body.appendChild(a);
        a.click();
        // return csvString;

        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Query & Download</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <QueryAndDownloadMap
                  selectedLocations={filterValues.locations.map(
                    (location) => location.location_ndx
                  )}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {hasParametersLoaded && hasLocationsLoaded && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "1070px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Groups Filter
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              fullWidth
                              data={parameterGroups}
                              valueField="parameter_group_name"
                              displayField="parameter_group_name"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterGroups",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterGroups}
                              name="parameterGroups"
                              label="Parameter Groups"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              width="264px"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  onFilterSelectAll(
                                    "parameters",
                                    parameters,
                                    "parameter_ndx"
                                  )
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={2}>
                          {parameters.length === 0 && (
                            <Typography
                              mt={3}
                              align="center"
                              variant="subtitle2"
                            >
                              ----- None Available -----
                            </Typography>
                          )}
                          <Chips
                            data={parameters}
                            valueField="parameter_ndx"
                            displayField="param_abbrev"
                            handleChipClick={(e) =>
                              handleFilterValues("parameters", e)
                            }
                            type="parameter-type"
                            activeChips={filterValues.parameters}
                            tooltipField1="parameter"
                            // tooltipField2="units_desc"
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Location Filter Groups
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={basins}
                            valueField="basin"
                            displayField="basin"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("basins", e.target);
                              }
                            }}
                            value={filterValues.basins}
                            name="basins"
                            label="Basins"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            width="264px"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={waterbodies}
                            valueField="waterbody"
                            displayField="waterbody"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("waterbodies", e.target);
                              }
                            }}
                            value={filterValues.waterbodies}
                            name="waterbodies"
                            label="Waterbodies"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            width="264px"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={counties}
                            valueField="county"
                            displayField="county"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("counties", e.target);
                              }
                            }}
                            value={filterValues.counties}
                            name="counties"
                            label="Counties"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            width="264px"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <MultiSelect
                            fullWidth
                            data={organizations}
                            valueField="organization"
                            displayField="organization"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("organizations", e.target);
                              }
                            }}
                            value={filterValues.organizations}
                            name="organizations"
                            label="Organizations"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            width="264px"
                          />
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Locations
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      locations: locations,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("locations")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Autocomplete
                              limitTags={3}
                              multiple
                              disableCloseOnSelect
                              id="locations"
                              name="locations"
                              options={locations}
                              value={filterValues.locations}
                              getOptionLabel={(option) => {
                                return option.location_id;
                              }}
                              // getOptionSelected={(option, value) =>
                              //   option.value === value.value
                              // }
                              filterSelectedOptions
                              onChange={(event, newValue) => {
                                handleFilterValues("locations", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="primary"
                                  variant="outlined"
                                  label="locations"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Time Period Selection
                      </Typography>
                      <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                              marginRight: "12px",
                            }}
                          >
                            <KeyboardDatePicker
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="start-date-picker"
                              label="Start Date"
                              value={filterValues.startDate}
                              onChange={(date) =>
                                handleFilterValues("startDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change start date",
                              }}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                            }}
                          >
                            <KeyboardDatePicker
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="end-date-picker"
                              label="End Date"
                              value={filterValues.endDate}
                              onChange={(date) =>
                                handleFilterValues("endDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change end date",
                              }}
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Box mt={2} style={{ width: "100%" }}>
                        {isSubmitLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            disabled={
                              !filterValues.parameters.length ||
                              !filterValues.locations.length ||
                              !filterValues.startDate ||
                              !filterValues.endDate ||
                              isExportLoading
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={() => handleSubmitFilters()}
                          >
                            Submit Filters
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Download Records
              </Typography>
            </AccordionSummary>
            <Box ml={3} mr={3} mb={3}>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={9} style={{ display: "flex" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography mr={2}>
                        Recordcount for Selected Data:
                      </Typography>
                      <Typography variant="h6" align="left">
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={filterValues.recordCount || 0}
                        />
                      </Typography>
                      {filterValues?.recordCount > 110000 && (
                        <Typography variant="subtitle2" color="error" ml={2}>
                          Maximum records for download is{" "}
                          <strong>110,000</strong>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    {isExportLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        disabled={
                          filterValues?.recordCount > 110000 || isSubmitLoading
                        }
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleExportClick}
                      >
                        <Typography
                          variant="h6"
                          style={{ textTransform: "uppercase" }}
                        >
                          Download
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(QueryAndDownload);
