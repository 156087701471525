import React from "react";

const Chapter4 = () => {
  return (
    <>
      <h2>Why should I care?</h2>
      <p>
        All natural waters contain some dissolved solids from contact with
        soils, rocks, and other natural materials. Too much, though, and
        dissolved solids can impair water use. Unpleasant taste, high
        water-treatment costs, and mineral accumulation in plumbing are among
        the problems associated with elevated concentrations of dissolved solids
      </p>
      <p>
        In addition, if TDS concentrations are too high or too low, the growth
        of aquatic life can be limited. High concentrations of TDS may also
        reduce water clarity, contribute to a decrease in photosynthesis,
        combine with toxic compounds and heavy metals, and lead to an increase
        in water temperature. High TDS can also be an issue in agriculture, with
        decreased crop yields due to higher salinity and TDS.
      </p>
    </>
  );
};

export default Chapter4;
