import React from "react";
import { Grid } from "@material-ui/core";

const Chapter2 = () => {
  return (
    <>
      <h2>Why is water quality important?</h2>
      <p>
        Water is essential to our environmental, social, and economic lives.
        Good water quality protects public health; provides wildlife habitats;
        is used for farming, fishing, and mining; and contributes to recreation
        and tourism. Water quality is closely linked to land use, is affected by
        urban development, and industrial use, agriculture, and recreation.
        Within the urban environment, stormwater runoff can carry bacteria,
        nutrients, trash and other pollutants that impact both natural swimming
        and recreation areas.
      </p>
      <Grid container direction="row" justify="center" spacing={6}>
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="TSS Sample"
              className="img img-responsive"
              src="/static/img/storylines/OilRefineryBeyondRiver.jpg"
              style={{
                width: "100%",
                maxHeight: "600px",
                objectFit: "contain",
              }}
            />
            <figcaption>
              Oil Refinery in Commerce City with the South Platte River in the
              foreground
            </figcaption>
          </figure>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="TSS Sample"
              className="img img-responsive"
              src="/static/img/storylines/bear-creek-near-golden-st.jpg"
              style={{
                width: "100%",
                maxHeight: "600px",
                objectFit: "contain",
              }}
            />
            <figcaption>Bear Creek Near Golden St </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter2;
