import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { INIT_STYLE_VALUES } from "../../constants";
import axios from "axios";
import { buildScale } from "../../../../utils";

const useLayerStyles = ({ onLayerStyleChange, routeType }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES(routeType));

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters/${routeType}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        basin: {
          ...prevState.basin,
          paint: {
            "circle-color": [
              "match",
              ["get", "basin"],
              ...buildScale(data.basin.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
        organizations: {
          ...prevState.organizations,
          paint: {
            "circle-color": [
              "match",
              ["get", "organization_fav"],
              ...buildScale(data.organizations.map((item) => item.display)),
              "#fff",
            ],
            "circle-stroke-width": [
              "case",
              [">", ["length", ["get", "organizations"]], 1],
              5,
              2,
            ],
            "circle-stroke-color": [
              "case",
              [">", ["length", ["get", "organizations"]], 1],
              "white",
              "black",
            ],
          },
        },
      }));
    }
  }, [data]); //eslint-disable-line

  const [activeStyle, setActiveStyle] = useState(styleValues.basin);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
