import React from "react";

const Chapter8 = () => {
  return (
    <>
      <h2>Impacts of Storm Runoff</h2>
      <p>
        TSS increases can often be attributed to urban stormwater runoff. In
        urban areas, natural water infiltration areas are limited due to large
        amounts of pavement and other impervious surfaces. Storm water and
        associated solids are carried rapidly from impervious surfaces through
        storm drains to creeks and rivers.
      </p>
    </>
  );
};

export default Chapter8;
