import React from "react";

const Chapter6 = () => {
  return (
    <>
      <h2>Regulation & Standards</h2>
      <p>
        The Colorado Department of Public Health and Environment (CDPHE)
        currently does not have a state-wide stream water quality standard for
        TDS or conductivity. The standard for chloride is 250 mg/L to protect
        domestic water supplies.
      </p>
      <p>
        The Environmental Protection Agency (EPA) has established a Secondary
        Drinking Water Standard for TDS of 500 mg/L. As a secondary standard,
        levels in this category are non-enforceable and have no known
        detrimental health effects, but only an aesthetic effect.
      </p>
      <p>
        Similarly, the EPA recommends through a Secondary Drinking Water
        Standard that drinking water has a conductivity value of less than 1,000
        µs/cm.
      </p>
    </>
  );
};

export default Chapter6;
