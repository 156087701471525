import React from "react";
import { Grid } from "@material-ui/core";

const Chapter7 = () => {
  return (
    <>
      <h2>When are TDS concentrations and conductivity the highest?</h2>
      <p>
        Total dissolved solids and conductivity are highest during storm runoff
        periods, flooding conditions, and other high flow conditions.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Monitoring Conductivity"
              className="img img-responsive"
              src="/static/img/storylines/south-platte-river-englewood.JPG"
              style={{ width: "100%" }}
            />
            <figcaption>
              Conductivity monitoring of the South Platte River in Englewood
              Colorado by a USGS gage.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="South Platte River Flooding"
              className="img img-responsive"
              src="/static/img/storylines/09152013 Fort Morgan exit 82.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              South Platte River Flooding in 2013 in Fort Morgan, CO.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter7;
