import React from "react";

const Chapter6 = () => {
  return (
    <>
      <h2>Regulation & Standards</h2>
      <p>
        The Colorado Department of Public Health and Environment (CDPHE)
        currently does not have a state-wide stream water quality standard for
        TSS or turbidity. CDPHE does regulate the discharge of suspended
        sediments through Regulation 62, Regulations for Effluent Limitations.
      </p>
      <p>
        There are no EPA drinking water primary or secondary standards for
        turbidity in drinking water. However, the EPA has a required process
        intended to reduce the level of turbidity based on the water source and
        the type of water treatment.
      </p>
    </>
  );
};

export default Chapter6;
