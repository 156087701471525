import React from "react";

const Chapter8 = () => {
  return (
    <>
      <h2>What can I do?</h2>
      <p>
        pH, temperature, and dissolved oxygen are natural characteristics of
        water, but people and their activities can help keep them in an optimal
        range for aquatic ecology. For example, pick up and properly dispose of
        pet waste, maintain septic tanks, and report illegal dumping or unusual
        conditions in lakes or streams.
      </p>
    </>
  );
};

export default Chapter8;
