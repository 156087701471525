import React from "react";
import { Grid } from "@material-ui/core";

const Chapter5 = () => {
  return (
    <>
      <h2>How do total dissolved solids (TDS) get into the river?</h2>
      <p>
        All natural waters contain some dissolved solids from contact with
        soils, rocks, and other natural materials. Soil erosion is caused by
        disturbance of a land surface. Soil erosion can be caused by building
        and road construction, forest fires, logging, and mining. The eroded
        soil particles may contain soluble components that can dissolve and be
        carried by stormwater to surface water. This will increase the TDS of
        the water body. Some rock and soil release ions very easily when water
        flows over them; for example, if acidic water flows over rocks
        containing calcite (CaCO3), such as calcareous shales, calcium (Ca2+)
        and carbonate (CO32-) ions will dissolve into the water. Therefore, TDS
        will increase. However, some rocks, such as quartz-rich granite, are
        very resistant to dissolution, and don’t dissolve easily when water
        flows over them. TDS of waters draining areas where the geology only
        consists of granite or other resistant rocks will be low (unless other
        factors are involved).
      </p>
      <p>
        As plants and animals decay, dissolved organic particles are released
        and can contribute to the TDS concentration.
      </p>
      <p>
        During storm events, pollutants such as fertilizers from lawns, deicers
        on streets in winter, and other particles from the land can be washed
        into streams and rivers. Because of the large amount of pavement in
        urban areas, dissolved solids can be carried through storm drains to
        creeks and rivers.
      </p>
      <p>
        The effluent from Wastewater Treatment Plants (WWTPs) adds dissolved
        solids to a stream. The wastewater from our houses contains both
        suspended and dissolved solids that we put down our drain. Most of the
        suspended solids are removed from the water at the WWTP before being
        discharged to the stream, but WWTPs only remove some of the TDS.
        Important components of the TDS load from WWTPs include phosphorus,
        nitrogen, and organic matter.
      </p>
      <p>
        Chloride is a major component of dissolved solids. Winter use of road
        salts for deicing also contributes chloride to surface water and
        groundwater. The presence of chloride increases the potential
        corrosivity of the water. Corrosion in water distribution systems
        affects infrastructure and drinking water quality. Source:
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="Rock Sald Runoff"
              className="img img-responsive"
              src="/static/img/storylines/road-salt-stains-runoff-637x510.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              Road salt stains from runoff on a bridge spanning a river.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter5;
