import React from "react";

import WaterQualityPage from "../WaterQualityPage";
import Chapter1 from "./Chapter1";
import Chapter2 from "./Chapter2";
import Chapter3 from "./Chapter3";
import Chapter4 from "./Chapter4";
import Chapter5 from "./Chapter5";
import Chapter6 from "./Chapter6";
import Chapter7 from "./Chapter7";
import Chapter8 from "./Chapter8";

function PHTemperatureDissolvedOxygen() {
  const links = [
    {
      chapter: "Chapter 1",
      links: [],
    },
    {
      chapter: "Chapter 2",
      links: [
        {
          title: "Source - USGS, pH and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/ph-and-water",
        },
        {
          title: "Source - USGS, Temperature and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/temperature-and-water",
        },
        {
          title: "Source - USGS, Dissolved Oxygen and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/dissolved-oxygen-and-water",
        },
      ],
    },
    {
      chapter: "Chapter 3",
      links: [
        {
          title: "Source - USGS, Multiple Water Quality Measuring Device",
          url: "https://www.usgs.gov/media/images/a-real-time-multiple-water-quality-measuring-device",
        },
        {
          title: "Source - USGS, Dissolved Oxygen Reading",
          url: "https://www.usgs.gov/media/images/usgs-scientist-taking-a-dissolved-oxygen-reading-a-small-stream",
        },
      ],
    },
    {
      chapter: "Chapter 4",
      links: [
        {
          title:
            "Source - A Citizen's Guide to Understanding and Monitoring Lakes and Streams",
          url: "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://apps.ecology.wa.gov/publications/documents/94149.pdf",
        },
        {
          title: "Source - USGS, Temperature and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/temperature-and-water",
        },
        {
          title: "Source - USGS, Dissolved Oxygen and Water",
          url: "https://www.usgs.gov/special-topics/water-science-school/science/dissolved-oxygen-and-water",
        },
      ],
    },
    {
      chapter: "Chapter 5",
      links: [],
    },
    {
      chapter: "Chapter 6",
      links: [],
    },
    {
      chapter: "Chapter 7",
      links: [
        {
          title:
            "Source - USGS, South Platte River at Abv Strontia Spgs Reservoir, CO",
          url: "https://waterdata.usgs.gov/monitoring-location/06707525/#parameterCode=00010&period=P7D",
        },
      ],
    },
    {
      chapter: "Chapter 8",
      links: [],
    },
  ];

  const title = "pH, Temperature, and Dissolved Oxygen";

  const description = "Learn what pH, Temperature, and Dissolved Oxygen is.";

  const chapters = [
    {
      number: "1",
      description: "Intro",
      body: <Chapter1 />,
    },
    {
      number: "2",
      description: "What is pH, temperature, and dissolved oxygen?",
      body: <Chapter2 />,
    },
    {
      number: "3",
      description: "How are they measured?",
      body: <Chapter3 />,
    },
    {
      number: "4",
      description: "Why should I care?",
      body: <Chapter4 />,
    },
    {
      number: "5",
      description:
        "How does pH, temperature and dissolved oxygen get into the river?",
      body: <Chapter5 />,
    },
    {
      number: "6",
      description: "Regulation & Standards",
      body: <Chapter6 />,
    },
    {
      number: "7",
      description: "What are the seasonal cycles of pH and dissolved oxygen?",
      body: <Chapter7 />,
    },
    {
      number: "8",
      description: "What can I do?",
      body: <Chapter8 />,
    },
  ];

  return (
    <WaterQualityPage
      links={links}
      title={title}
      description={description}
      chapters={chapters}
    />
  );
}

export default PHTemperatureDissolvedOxygen;
