import React from "react";

const Chapter2 = () => {
  return (
    <>
      <h2>What is pH, temperature, and dissolved oxygen?</h2>
      <p>
        Temperature, pH and dissolved oxygen are typically monitored in-situ, or
        directly in the stream, using field equipment with scientists recording
        the results at the time of field sampling. These data provide an
        immediate and inexpensive results that can indicate the basic health of
        a water body.
      </p>
      <h3>pH</h3>
      <p>
        The pH scale ranges from 0 to 14 and measures the acidity or basicity of
        water. A pH of 7 is neutral. A pH of less than 7 indicates the water is
        acidic and a pH of greater than 7 indicates the water is basic. To
        access acidity or basicity, pH measures the relative amount of free
        hydrogen and hydroxyl ions in the water. Water that has more free
        hydrogen ions is acidic, whereas water that has more free hydroxyl ions
        is basic. Since pH can be affected by chemicals in the water, pH is an
        important indicator of water that is changing chemically.
      </p>
      <h3>Temperature</h3>
      <p>
        Temperature can have a large effect on water chemistry and biological
        activity within streams, rivers, lakes, and reservoirs. Temperature
        governs the kinds of organisms that can live in rivers and lakes. Fish,
        insects, zooplankton, phytoplankton, and other aquatic species all have
        a preferred temperature range. As temperatures get too far above or
        below this preferred range, the number of individuals of the species
        decreases. Temperature is also important because of its influence on
        water chemistry. The rate of chemical reactions generally increases at
        higher temperature. Some compounds are also more toxic to aquatic life
        at higher temperatures.
      </p>
      <p>
        Warm water holds less dissolved oxygen than cool water and may not
        contain enough dissolved oxygen for the survival of different species of
        aquatic life.
      </p>
      <h3>Dissolved Oxygen</h3>
      <p>
        Dissolved oxygen (DO) is a measure of how much oxygen is dissolved in
        the water - the amount of oxygen available to living aquatic organisms.
        Rapidly moving water, such as in a mountain stream or large river, tends
        to contain a lot of dissolved oxygen, whereas stagnant water contains
        less. Bacteria in water can consume oxygen as organic matter decays.
        Thus, excess organic material in lakes and rivers can cause eutrophic
        conditions, which is an oxygen-deficient situation that can cause harm
        to aquatic life. Aquatic life can have a hard time in stagnant water,
        especially in summer when dissolved oxygen levels are at seasonal lows.
      </p>
    </>
  );
};

export default Chapter2;
