import React from "react";

const Chapter7 = () => {
  return (
    <>
      <h2>When are TSS concentrations and turbidity the highest?</h2>
      <p>
        TSS and turbidity is highest during and after storm events in the spring
        and fall causing erosion and urban runoff to enter the streams.
      </p>
    </>
  );
};

export default Chapter7;
