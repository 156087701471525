import React from "react";

const Chapter1 = () => {
  return (
    <>
      <h2>Total Dissolved Solids (TDS) and Conductivity Storyline</h2>
      <p>
        Don't know much about total dissolved solids (TDS) and conductivity? The
        storyline is the perfect place to start. Learn not only what TDS and
        conductivity are, but additionally what can be done to keep these in
        balance in the South Platte River and its tributaries.
      </p>
    </>
  );
};

export default Chapter1;
