import React from "react";

const Chapter1 = () => {
  return (
    <>
      <h2>pH, Temperature and Dissolved Oxygen Storyline Storyline</h2>
      <p>
        Don’t know much about pH, temperature, or dissolved oxygen? This
        storyline is a perfect place to start. Learn not only what pH,
        temperature, and dissolved oxygen are, but additionally what can be done
        to keep these in balance in the South Platte River and its tributaries.
      </p>
    </>
  );
};

export default Chapter1;
