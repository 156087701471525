import React from "react";

const Chapter7 = () => {
  return (
    <>
      <h2>When are most nutrients present in the South Platte River?</h2>
      <p>
        Nitrate concentrations tend to be higher downstream of wastewater
        treatment plants.
      </p>
    </>
  );
};

export default Chapter7;
