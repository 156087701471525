import React from "react";
import { Grid } from "@material-ui/core";

const Chapter2 = () => {
  return (
    <>
      <h2>What are TSS and Turbidity?</h2>
      <p>
        Total suspended solids (TSS) is a measurement of the suspended particles
        in water. TSS can include silt, sediment, bacteria, organic matter, and
        other insoluble material. The concentration of TSS in water is
        indicative of upstream erosion, but also can be elevated by inputs from
        wastewater treatment plants, industry, urban/suburban storm runoff, and
        agriculture. TSS refers to the suspended particles in water, TDS refers
        to the dissolved particles within water.
      </p>
      <p>
        Turbidity is a surrogate for TSS and is the measure of relative clarity
        of a liquid. It is an optical characteristic of water and is a
        measurement of the amount of light that is scattered by material in the
        water when a light is shined through the water sample. The higher the
        intensity of scattered light, the higher the turbidity.
      </p>
      <Grid container direction="row" justify="center">
        <Grid
          item
          xs={12}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <figure style={{ width: "100%" }}>
            <img
              alt="TSS Sample"
              className="img img-responsive"
              src="/static/img/storylines/wss-sediment-sampling-grand-canyon.jpg"
              style={{ width: "100%" }}
            />
            <figcaption>
              A USGS hydrographer collecting a suspended-sediment water sample
              from the Little Colorado River, Grand Canyon, Arizona, USA.
            </figcaption>
          </figure>
        </Grid>
      </Grid>
    </>
  );
};

export default Chapter2;
