/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  // Activity,
  Database,
  FileText,
  Home,
  Monitor,
  Users,
  Map as MapIcon,
  Image as WatershedIcon,
  Book,
  Share2,
  Clipboard,
  Droplet,
  HelpCircle,
  Thermometer,
} from "react-feather";

import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import { CrudProvider } from "../CrudProvider";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import Default from "../pages/dashboards/Default";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import ListWqatActivityTypes from "../pages/dataManagement/ListWqatActivityTypes";
import ListWqatParameters from "../pages/dataManagement/ListWqatParameters";
import ListWqatOrganizations from "../pages/dataManagement/ListWqatOrganizations";
import ListWqatMediaTypes from "../pages/dataManagement/ListWqatMediaTypes";
import ListWqatLocations from "../pages/dataManagement/ListWqatLocations";
import ListWqatLocationTypes from "../pages/dataManagement/ListWqatLocationTypes";
import WhatIsWaterQuality from "../pages/storyLines/waterQuality/whatIsWaterQuality/WhatIsWaterQuality";
import EColi from "../pages/storyLines/waterQuality/eColi/EColi";
import Nutrients from "../pages/storyLines/waterQuality/nutrients/Nutrients";
import ContaminantsOfEmergingConcern from "../pages/storyLines/waterQuality/contaminantsOfEmergingConcern/ContaminantsOfEmergingConcern";
import TotalDissolvedSolids from "../pages/storyLines/waterQuality/totalDissolvedSolids/TotalDissolvedSolids";
import TotalSuspendedSolids from "../pages/storyLines/waterQuality/totalSuspendedSolids/TotalSuspendedSolids";
import ParameterGroupsToParameters from "../pages/dataManagement/associations";
import WaterQualityStorylineHome from "../pages/storyLines/waterQuality/WaterQualityStorylineHome";
import MetalsAndOtherTraceElements from "../pages/storyLines/waterQuality/metalsAndOtherTraceElements/MetalsAndOtherTraceElements";
import WaterbodyMap from "../pages/publicMap/waterbodyMap";
import DataSources from "../pages/dataAccess/reports/DataSources";
import DeveloperGuard from "../components/DeveloperGuard";
import DeveloperVisibilityFilter from "../components/DeveloperVisibilityFilter";
import QueryAndDownload from "../pages/dataAccess/QueryAndDownload/QueryAndDownload";
import {
  BlurCircular,
  Eco,
  LocalDrink,
  ScatterPlot,
  Warning,
  Widgets,
} from "@material-ui/icons";
import Resources from "../pages/dataAccess/Resources";
import PHTemperatureDissolvedOxygen from "../pages/storyLines/waterQuality/phTemperatureDissolvedOxygen/PHTemperatureDissolvedOxygen";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: item.sidebarName ?? inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

const getCrudRoutes = (list) => {
  return list.map((item) => {
    const config = require(`../pages/models/${item.name}Config`);
    const slug = inflector.dasherize(inflector.underscore(item.name));

    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      component: CrudIndexPage,
      provider: CrudProvider,
      config,
      crud: [
        {
          path: `/models/${slug}/:id`,
          name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
        {
          path: `/models/${slug}/add`,
          name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
      ],
    };
  });
};

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

const publicMapRoutes = {
  header: "Data Access",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/data-access/map-explorer",
  name: "Map Explore",
  component: PublicMap,
};

const watershedMapRoutes = {
  id: "Watershed Explorer",
  icon: <WatershedIcon />,
  path: "/data-access/watershed-explorer",
  name: "Watershed Explore",
  component: WaterbodyMap,
};

// const dataAccessRoutes = {
//   id: "Graph Explorer",
//   icon: <Activity />,
//   path: "/data-access/graph-explorer",
//   name: "Graph Explorer",
//   component: Blank,
// };

const queryRoutes = {
  id: "Query & Download",
  icon: <FileText />,
  path: "/data-access/query-&-download",
  name: "Query & Download",
  component: QueryAndDownload,
};

const reportsRoutes = {
  id: "Data Sources",
  icon: <Clipboard />,
  path: "/data-access/data-sources",
  name: "Data Sources",
  component: DataSources,
};

const resourcesRoutes = {
  id: "Resources",
  icon: <HelpCircle />,
  path: "/data-access/resources",
  name: "Resources",
  component: Resources,
};

const dataScrubbingRoutes = {
  header: "Data Management",
  id: "Data Scrubbing",
  icon: <Database />,
  children: [
    {
      path: "/data-management/activity-types",
      name: "Activity Types",
      component: ListWqatActivityTypes,
      guard: DeveloperGuard,
      visibilityFilter: DeveloperVisibilityFilter,
    },
    {
      path: "/data-management/location-types",
      name: "Location Types",
      component: ListWqatLocationTypes,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/locations",
      name: "Locations",
      component: ListWqatLocations,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/media-types",
      name: "Media Types",
      component: ListWqatMediaTypes,
      guard: DeveloperGuard,
      visibilityFilter: DeveloperVisibilityFilter,
    },
    {
      path: "/data-management/organizations",
      name: "Organizations",
      component: ListWqatOrganizations,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/parameters",
      name: "Parameters",
      component: ListWqatParameters,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    // {
    //   path: "/data-management/waterbodies",
    //   name: "Water Bodies",
    //   component: ListWqatWaterbodies,
    //   guard: AdminGuard,
    // },
  ],
  visibilityFilter: AdminVisibilityFilter,
};

const associationsRoutes = {
  id: "Associations",
  icon: <Share2 />,
  children: [
    {
      path: "/data-management/parameter-groups-to-parameters",
      name: "Parameter Groups to Parameters",
      component: ParameterGroupsToParameters,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

const landingRoutes = {
  id: "Landing Page",
  path: "/login",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const storylineHomeRoute = {
  id: "Storylines",
  path: "/storylines/water-quality",
  header: "Storylines",
  icon: <Book />,
  component: WaterQualityStorylineHome,
  children: null,
};

const mainRoutes = {
  header: "Dashboards",
  id: "Water Quality Assessment Tool",
  path: "/",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
};

const whatIsWaterQualityRoute = {
  header: "Storylines",
  icon: <Droplet />,
  id: "What is Water Quality",
  path: "/storylines/water-quality/what-is-water-quality",
  name: "What is Water Quality?",
  component: WhatIsWaterQuality,
};

const eColiRoute = {
  icon: <BlurCircular />,
  id: "E. coli",
  path: "/storylines/water-quality/e-coli",
  name: "E. coli",
  component: EColi,
};

const nutrientsRoute = {
  icon: <Eco />,
  id: "Nutrients",
  path: "/storylines/water-quality/nutrients",
  name: "Nutrients",
  component: Nutrients,
};

const contaminantsOfEmergingConcernRoute = {
  icon: <Warning />,
  id: "Contaminants of Emerging Concern",
  path: "/storylines/water-quality/contaminants-of-emerging-concern",
  name: "Contaminants of Emerging Concern",
  component: ContaminantsOfEmergingConcern,
};

const totalDissolvedSolidsRoute = {
  icon: <LocalDrink />,
  id: "TDS & Conductivity",
  path: "/storylines/water-quality/tds-and-conductivity",
  name: "TDS & Conductivity",
  component: TotalDissolvedSolids,
};

const totalSuspendedSolidsRoute = {
  icon: <ScatterPlot />,
  id: "TSS & Turbidity",
  path: "/storylines/water-quality/tss-and-turbidity",
  name: "TSS & Turbidity",
  component: TotalSuspendedSolids,
};

const metalsAndOtherTraceElementsRoute = {
  icon: <Widgets />,
  id: "Metals and Other Trace Elements",
  path: "/storylines/water-quality/metals-and-other-trace-elements",
  name: "Metals and Other Trace Elements",
  component: MetalsAndOtherTraceElements,
};

const PHTemperatureDissolvedOxygenRoute = {
  icon: <Thermometer />,
  id: "pH, Temperature & Dissolved Oxygen",
  path: "/storylines/water-quality/ph-temperature-and-dissolved-oxygen",
  name: "pH, Temperature & Dissolved Oxygen",
  component: PHTemperatureDissolvedOxygen,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  dataScrubbingRoutes,
  associationsRoutes,
  // dataAccessRoutes,
  queryRoutes,
  reportsRoutes,
  resourcesRoutes,
  accountRoutes,
  whatIsWaterQualityRoute,
  eColiRoute,
  nutrientsRoute,
  contaminantsOfEmergingConcernRoute,
  totalDissolvedSolidsRoute,
  totalSuspendedSolidsRoute,
  metalsAndOtherTraceElementsRoute,
  PHTemperatureDissolvedOxygenRoute,
  storylineHomeRoute,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  ...modelCrudRoutes,
  publicMapRoutes,
  watershedMapRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  ...crudSidebarMenu,
  dataScrubbingRoutes,
  associationsRoutes,
  publicMapRoutes,
  watershedMapRoutes,
  // dataAccessRoutes,
  queryRoutes,
  reportsRoutes,
  resourcesRoutes,
  whatIsWaterQualityRoute,
  eColiRoute,
  nutrientsRoute,
  contaminantsOfEmergingConcernRoute,
  totalDissolvedSolidsRoute,
  totalSuspendedSolidsRoute,
  metalsAndOtherTraceElementsRoute,
  PHTemperatureDissolvedOxygenRoute,
];
